import React from 'react'

import Container from '../Container';
import { Row, Col } from '../../../../components/Bootstrap/Grid';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../../colors.module.scss';
const { className } = new CssClassNames(styles);

export default function HablamosSection({firstTitle, firstText ,textItems = []}) {
    return (
        <section {...className(`${styles['hablamos-section']}`)}>
            <Container>
                <Row {...className(`position-relative`)}>
                    <Col col={{xs: 12, lg: 6}} {...className(`h-100 position-absolute box-bg`)}>
                        <div {...className(`${colors['bg-fourth']} ml-n3 mr-n5 mx-sm-0 h-100`)} data-sal="slide-up" />
                    </Col>
                    <Col col={{xs: 12, lg: 6}}  pb={{xs: 7, lg: 0}} {...className('position-relative')}>
                        <div {...className('box-fix bg-white d-block d-lg-none ml-n5 mr-n5 mx-sm-0 position-absolute h-100')}/>
                        <div {...className(`d-flex flex-column align-items-center position-relative h-100 w-100 px-lg-10 px-xl-12 px-xxl-13`)} data-sal="slide-up">
                            <h2 {...className(`d1 font-weight-normal text-black mb-4 mb-lg-7 mb-xxl-10`)}>
                                {firstTitle}
                            </h2>
                            <p {...className(`h5 text-black mb-0`)}>
                                {firstText}
                            </p>
                        </div>
                    </Col>

                    <Col col={{xs: 12, sm: 10, lg: 4}} offset={{sm: 1}} pt={{xs: 7, lg: 9, xl: 11}} pb={{xs: 7, lg: 9, xl: 11}}>
                        <div>
                            {textItems.map((item, i) => (
                                <div key={i} {...className(`${i+1 < textItems.length ? 'mb-5 mb-lg-7 mb-xl-8 mb-xxl-9': 'mb-0'}`)} data-sal="slide-up">
                                    <h3 {...className(`h2 font-weight-normal ${colors['text-primary']} mb-2 mb-lg-3 mb-xxl-4`)}>
                                        {item.title}
                                    </h3>
                                    <p {...className('p text-black mb-0')}>
                                        {item.text}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
