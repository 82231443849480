import React from 'react';

import LayoutNormal from './LayoutNormal';
import LayoutFull from './LayoutFull';
import LayoutLogin from './LayoutLogin';
import Home from '../../Home';

const Layout = ({ layout = null, ...props }) => {
    switch (layout) {
        case 'full-width':
            return <LayoutFull {...props} />
        case 'login':
            return <LayoutLogin {...props} />
        case 'home':
            return <Home {...props} />
        default:
            return <LayoutNormal {...props} />
    }
}

export default Layout;