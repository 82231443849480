import React from 'react';
import { Link } from "gatsby";
import GSImage from '../components/gatsbySharedComponents/GSImage';
import ImageSlider from '../sharedComponents/components/SCNaeSlider/ImageSlider';

const isSharedComponent = (WrappedComponent, sharedComponentName = '') => {
    return (props) => {
        switch (sharedComponentName) {
            case 'SCNaeDownloadButton': 
                return (
                    <WrappedComponent {...props} tag={Link} gatsbyLink={true} />
                );   
            case 'SCLinkButton':
                const isExternalLink = (/^http[s]?/).test(props.href);
                return (
                    <WrappedComponent {...props} {...!isExternalLink && {tag: Link, gatsbyLink: true} } />
                );            
            case 'SCImage':
                return (
                    <WrappedComponent {...props}>
                        <GSImage {...props} />
                    </WrappedComponent>
                );            
            case 'SCNaeSlider':
                return (
                    <WrappedComponent {...props}>                        
                        {props.naeSliderItems.map((item, i) => (
                            <ImageSlider key={i} url={item.gatsbyImage ? item.gatsbyImage.publicURL: item.url} title={item.title} />
                        ))}
                    </WrappedComponent>
                );            
            default:
                return (<WrappedComponent {...props} />);
        }
    }
};

export default isSharedComponent;
