import React from 'react';
import Placeholder from '../../../sharedComponents/assets/placeholder-image.png';

import styles from './styles.module.scss';

const ImageSlider = ({ url = null, title, customSlide = null }) => {
    return (
        <div>
            <div className={customSlide || styles.slide} style={{backgroundImage: `url(${url || Placeholder})`}} role="img" aria-label={title} />
        </div>
    );
};

export default ImageSlider;