import React from 'react';
import { Link } from 'gatsby';

import Container from '../Container';
import { Row, Col } from '../../../../components/Bootstrap/Grid';
import LogoComponent from '../../parts/Navbar/NavbarBrandComponent';
import Img from '../../assets/image-hero.jpg'
import Comma from '../../parts/Comma';

import { getSvg } from '../../userconfig';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../../colors.module.scss';
const { className } = new CssClassNames(styles);

export default function HeroSection({title, loginText, lang, languageText, path, logo, comma}) {
    return (
        <section {...className(`${styles['hero-section']} d-flex flex-column ${colors['bg-primary']}`)}>
            <div {...className(`${styles.header}`)}>
                <Container {...className(`d-flex justify-content-between h-100`)}>
                    {logo ? <LogoComponent href='/' parent='HomeHeader' placeholderLogo={getSvg(64, 64, '#ffffff', logo.p)} /> : <div />}
                    <div {...className('d-flex')}>
                        <Link to={lang === 'es' ? '/pt-br/' : '/'} {...className('link link-sub h6 d-flex align-self-center align-items-center')}>
                            <span>{languageText}</span>
                        </Link>
                        <Link to={path} {...className('link h6 d-flex align-self-center align-items-center')}>
                            {loginText}
                        </Link>
                    </div>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col col={{xs: 12, lg: 6}}>
                        <h1 {...className(`${styles['hero-title']} d1-700 text-white mb-0`)}>
                            {title}
                        </h1>
                    </Col>
                    <Col col={{xs: 9, lg: 6}} {...className('d-flex pt-9 pt-lg-7 pt-xl-9')}>
                        <div {...className(`${styles['wrapper-img']} position-relative`)}>
                            <div {...className(`${styles.comma} position-absolute`)}>
                                {comma ? <Comma customComma={[{ x: 64, y: 64 }, '#ffffff', comma.p]} /> : null}
                            </div>
                            <img style={{objectFit: 'cover', width: '100%', height: '100%'}} src={Img} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
