import React from 'react';
import isSharedComponent from '../../hoc/isSharedComponent';

import Button from '../Bootstrap/Button';
import DownloadButton from '../DownloadButton';

const isAllowed = () => (typeof window !== 'undefined' && window.localStorage.getItem('nae-logo-selected'));
const getUrl = (baseUrl) => {
    const data = JSON.parse(window.localStorage.getItem('nae-logo-selected'));
    return baseUrl.replace('{logoId}', data.logo.id).replace('{colorId}', data.color.id);
}

const SCNaeDownloadButton = ({ text, url, tag = 'a', lang }) => (
    isAllowed() ? (
        <DownloadButton gatsbyFile={{ publicURL: getUrl(url) }}>{text}</DownloadButton>
    ) : (
        <Button 
            href={lang === 'pt-br' ? "/pt-br/personalize-seu-nae/" : "/personaliza-tu-nae/"}
            tag={tag}
            gatsbyLink={true}
        >{lang === 'pt-br' ? 'Crie seu logo' : 'Crea tu logo'}</Button>
    )
);

export default isSharedComponent(SCNaeDownloadButton, 'SCNaeDownloadButton');
