import React, { forwardRef } from 'react';

import { generatePaddingClasses } from '../../../utils';

import CssClassNames, { getCssProp } from '../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);


export const Container = ({ tag, children, isFluid = false, ...props }) => {
    const Tag = ['section', 'main'].includes(tag) ? tag : 'div';
    
    let classes = [];

    classes = generatePaddingClasses(props.pt, 't', classes);
    classes = generatePaddingClasses(props.pb, 'b', classes);  

    return (
        <Tag {...className(`${classes.join(' ')}
                ${props.className || ''}
                ${isFluid ? 'container-fluid' : 'container'}
                ${getCssProp(props, ['mt', 'mb'])}
            `)}
            {...props.style && { style: props.style }}
            {...(props.id && { id: props.id })}
            {...(props['data-role'] && { 'data-role': props['data-role'] })}
        >{children}</Tag>
    )
}

export const Row = ({ children, isRelative, pt, pb, gutter = true, ...props }) => {
    let classes = [ 'row' ];

    classes = generatePaddingClasses(pt, 't', classes);
    classes = generatePaddingClasses(pb, 'b', classes); 
    
    if (props.className) {
        classes.push(props.className);
    }

    return (
        <div {...props.style && { style: props.style }} {...className(`${classes.join(' ')}      
            ${isRelative ? 'position-relative' : ''}
            ${!gutter ? 'no-gutters' : ''}
        `)}>{children}</div>
    )
}

export const Col = forwardRef(({ children, tag, extraProps = {}, ...props }, ref) => {
    const Tag = ['p', 'label'].includes(tag) ? tag : 'div';

    return (
        <Tag ref={ref} {...className(`${props.className || ''} ${getCssProp(props, ['col', 'offset', 'pt', 'pb', 'mt', 'mb', 'order'])}`)} {...extraProps} {...props.style && { style: props.style }}>
            {children}
        </Tag>
    )
})

export const Spacer = (props) => (<div {...className(`${getCssProp(props, ['offset', 'order', 'pl', 'pr', 'pt', 'pb'])}`)} />);