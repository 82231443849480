import utility from './SCutilities.module.scss';
import text from './SCtext.module.scss';
import transition from './SCtransitions.module.scss';

const globalStyles = {
    ...utility,
    ...text,
    ...transition
};

export const getCssProp = (props, keyProps = null, keyClassPrefix = null) => {
    if (keyProps === null) {
        keyProps = Object.keys(props);
    } if (typeof keyProps === 'string') {
        keyProps = [ keyProps ];
    }

    let cssProp = [];

    keyProps.forEach(keyProp => {
        const classPrefix = keyClassPrefix && keyClassPrefix[keyProp] ? keyClassPrefix[keyProp] : keyProp;

        if (props[keyProp] !== null) {
            switch (typeof props[keyProp]) {
                case 'object':
                    Object.keys(props[keyProp]).reduce((css, key) => {
                        if (typeof props[keyProp][key] !== 'undefined') {
                            css.push(`${classPrefix}-${(key !== 'xs') ? `${key}-` : ''}${props[keyProp][key]}`);
                        }
                        return css;
                    }, cssProp);
                break;
                case 'undefined':
                break;
                default:
                    cssProp.push(`${classPrefix}-${props[keyProp]}`);
        }
        }
    });

    return cssProp.join(' ');
}

export default class CssClassNames {
    constructor(CSSModulesObject = {}) {
        let theStyles = {};

        if (typeof CSSModulesObject === 'object') {
            theStyles = {
                ...globalStyles,
                ...CSSModulesObject,
            };
        }

        this.styleNames = theStyles;
        this.camelize = this.camelize.bind(this);
        this.className = this.className.bind(this);
    }

    camelize(str) {
        return str.replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
    }

    className(cssClasses, parentClassNames = '', keyPropName = 'className') {
        cssClasses = cssClasses || parentClassNames;

        const obj = {};

        if (!cssClasses) {
            return obj;
        }

        obj[keyPropName] = cssClasses
            .replace(/\s+/g, ' ')
            .split(/ |\+|\n|\r/)
            .map(key => {
                const style = this.styleNames[key] || this.styleNames[this.camelize(key)]
                return (style && typeof style === 'string') ? style : key
            })
            .join(' ')
            .trim();
        return obj;
    }
}