import React, { memo } from 'react';

import CssClassNames from '../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const handleOnClick = (e, active) => {
    if (active) {
        e.preventDefault();
    }
}

const Navbar = memo(({ navItems = [], navbarBrandContent = null, fixed = false, navAlign = 'left', extraContent = null, NavItem = null, ...props }) => {
    return (
        <nav {...className(`navbar navbar-light bg-light navbar-expand-sm ${props.className ? props.className : ''} ${fixed ? 'position-fixed fixed-top' : ''}`)}>
            <div {...className(`navbar-brand`)}>{navbarBrandContent ? navbarBrandContent : 'LOGO'}</div>
            <ul {...className(`navbar-nav ${navAlign === 'right' ? 'ml-auto mr-0' : ''} ${props.navClassName ? props.navClassName : ''}`)}>
                {navItems.map((navItem, i) => (
                    <li key={i} {...className(`nav-item ${navItem.active ? 'active' : ''}`)}>
                        {NavItem ? <NavItem {...navItem} {...className(`nav-link ${navItem.active ? 'active' : ''} ${navItem.className ? navItem.className : ''}`)} /> : <NavbarItem {...navItem} />}
                    </li>
                ))}
            </ul>
            {extraContent}
        </nav>
    );
});

const NavbarItem = ({ active, href, title, ...props }) => (
    <a 
        {...className(`nav-link ${active ? 'active' : ''} ${props.className ? props.className : ''}`)} 
        href={href} 
        data-title={title}
        onClick={e => handleOnClick(e, active)}
    >
        <span>{title}</span>
    </a>
);

export default Navbar;