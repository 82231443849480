import React from 'react'

import Container from '../Container';
import { Row, Col } from '../../../../components/Bootstrap/Grid';
import NaeImage from '../../parts/NaeImage';
import Img2 from '../../assets/image-laughing.jpg'
import Img from '../../assets/image-somos-1.jpg'

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../../colors.module.scss';
const { className } = new CssClassNames(styles);

export default function SomosSection({firstTitle, firstText, secondText}) {
    return (
        <section {...className(`${styles['somos-section']} ${colors['bg-secondary']}`)}>
            <Container>
                <Row {...className(`mb-7 mb-lg-12  mb-xl-10 mb-xxl-12`)}>
                    <Col col={{xs: 12, lg: 7}}  mb={{xs: 7, lg: 0}} {...className('order-1 order-lg-2')}>
                        <div {...className(`d-flex flex-column align-items-start h-100 w-100 px-lg-10 px-xl-12 px-xxl-13`)} data-sal="slide-up" data-sal-delay="300">
                            <h2 {...className(`d1 font-weight-normal text-black mb-4 mb-lg-7 mb-xxl-10`)}>
                                {firstTitle}
                            </h2>
                            <p {...className(`h5 text-black mb-0`)}>
                                {firstText}
                            </p>
                        </div>
                    </Col>
                    <Col col={{xs: 12, lg: 5}} {...className('order-2 order-lg-1')} extraProps={{['data-sal']: "slide-up"}}>
                        <NaeImage
                            url={Img}
                            title="Somos 1"
                            relation="1_1"
                            position="bottom_left"
                            customClass={styles['first-img']}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col col={{xs: 12, lg: 4}} offset={{lg: 1}}  mb={{xs: 7, lg: 0}}>
                        <div {...className(`d-flex align-items-center h-100`)} data-sal="slide-up" data-sal-delay="300">
                            <p {...className(`h5 text-black mb-0`)}>
                                {secondText}
                            </p>
                        </div>
                    </Col>
                    <Col col={{xs: 12, lg: 6}} offset={{lg: 1}} extraProps={{['data-sal']: "slide-up"}}>
                        <NaeImage
                            url={Img2}
                            title="laughing"
                            relation="2_1"
                            position="bottom_right"
                            customBgClass="bg-white"
                            customClass={styles['second-img']}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
