import React from 'react'

import Container from '../Container';
import { Row, Col } from '../../../../components/Bootstrap/Grid';
import Video from '../../assets/video.mp4';
import Cover from '../../assets/video-cover.jpg';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

export default function VideoSection({title}) {
    return (
        <section {...className(`${styles['video-section']}`)}>
            <Container>
                <Row>
                    <Col col={{xs: 12, lg: 9, xl: 8, xxl: 9}}>
                        <h2 {...className('title d1 font-weight-normal text-black')} data-sal="slide-up">
                            {title}
                        </h2>
                    </Col>
                    <Col col={12}>
                        <div data-sal="slide-up" {...className('wrapper-video mx-n5 mx-sm-0')}>
                            <video controls poster={Cover}>
                                <source src={Video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
