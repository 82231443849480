import React, { useState, useEffect, useRef } from 'react';

import { Row, Col } from '../../../../components/Bootstrap/Grid';
import SCHeading from '../../../../components/SCHeading';
import SCNaeDownloadButton from '../../../../components/SCNaeDownloadButton';

import { getElementOffsetTop, scrollIt } from '../../../../utils';

import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

import colors from '../../colors.module.scss';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const SCROLL_OFFSET = -120;

const LayoutNormal = ({ children, currentPage, navigatePages, lang, indexPath }) => {
    const [ showSidebar, setShowSidebar ] = useState(false);
    const sidebarRef = useRef(null);
    const hasSidebar = currentPage && currentPage.sections && currentPage.sections.length > 1;
    const isFirst = currentPage && navigatePages.length && currentPage.id === navigatePages[0].id ? true : false;

    useEffect(() => {
        const onScroll = () => {
            const st = window.pageYOffset || document.documentElement.scrollTop;
            setShowSidebar(st > getElementOffsetTop(sidebarRef.current, SCROLL_OFFSET * 3));
        }

        if (window.location.hash && window.location.hash.replace('#', '')) {
            setTimeout(() => {
                const element = window.document.getElementById(window.location.hash.replace('#', ''));
                if (element) {
                    scrollIt(element, 200, SCROLL_OFFSET);
                }
            }, 0);
        }

        window.addEventListener('scroll', onScroll, false);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);
    
    return (
        <div>
			<Navbar indexPath={indexPath} lang={lang} navigatePages={navigatePages} currentPage={currentPage} />
            {currentPage.hero && 
                <Row gutter={false} {...className('hero mb-14 mb-lg-8')}>
                    <Col order={isFirst ? { xs: 2, lg: 1 } : {}} col={{ xs: 12, lg: 6 }}>
                        <div {...className('hero-content')}>
                            <SCHeading {...className('hero-title pr-lg-1 pr-xl-3')} pb={{ xs: 6, xl: 8 }} pt={{ xs: 6, lg: 1 }} tag="h1" content={currentPage.hero.title ? currentPage.hero.title : currentPage.title} />
                            <div {...className('pr-xl-10 pr-xxl-12')}>
                                <p {...className(`${currentPage.hero.buttonText ? 'mb-4 mb-lg-6' : ''} pr-lg-9 pr-xl-12`)} dangerouslySetInnerHTML={{ __html: currentPage.hero.description }}></p>
                                {currentPage.hero.buttonText ? (
                                    currentPage.hero.buttonType === 'anchor' ? (
                                        <a 
                                            {...className('hero-anchor')}
                                            href={`#${currentPage.sections && currentPage.sections.length ? currentPage.sections[0].id : ''}`}
                                            onClick={e => {
                                                e.preventDefault();

                                                if (!currentPage.sections || !currentPage.sections.length) {
                                                    return;
                                                }
                                                
                                                const element = document.getElementById(currentPage.sections[0].id);
                                                if (element) {
                                                    scrollIt(element, 2, SCROLL_OFFSET * 1.2);
                                                }
                                            }}
                                        >{currentPage.hero.buttonText}</a>
                                    ) : (
                                        <SCNaeDownloadButton
                                            lang={lang}
                                            text={currentPage.hero.buttonText}
                                            url={currentPage.hero.buttonHref ? currentPage.hero.buttonHref : '#'}
                                            />
                                    )
                                ) : null}
                            </div>
                        </div>
                    </Col>
                    <Col order={isFirst ? { xs: 1, lg: 2 } : {}} pt={isFirst ? 0 : { xs: 6, lg: 0 }} col={{ xs: 12, lg: 6 }}>
                        <div {...className(`hero-image-wrapper ${isFirst ? 'first' : ''} ${colors['bg-fourth']}`)}>
                            {currentPage.hero.gatsbyImage ? (
                                <div {...className(`hero-image ${isFirst ? 'first' : ''}`)} style={{
                                    backgroundImage: `url(${currentPage.hero.gatsbyImage.childImageSharp.resize.src})`
                                }} />
                            ) : null}
                        </div>
                    </Col>
                </Row>
            }
            <Row gutter={false}>
                <Col ref={sidebarRef} col={{ lg: 3 }} />
                <Col {...className('main-content')} col={{ lg: 9 }}>
                    {children}
                </Col>
            </Row>
            <Footer />
            {hasSidebar ? (
                <Sidebar
                    show={showSidebar}
                    scrollOffset={SCROLL_OFFSET}
                    items={currentPage && currentPage.sections.length > 1 ? currentPage.sections.map(({ title, id }) => ({ title, id })) : []} 
                />
            ) : null}
        </div>
    );
}

export default LayoutNormal;
