import React, {useEffect, memo, useState} from 'react';
import BCEvents from '../../../../modules/BCEvents/BCEvents';

// TODO: call gatsby here? weird
import { Link } from 'gatsby';

import { getUserLogo, hasConfig } from '../../userconfig';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);


export default memo(function Navbar({ href = '#', parent= 'NavbarBrandComponent', placeholderLogo = null}) {
	const [ render, setRender ] = useState(0);

	useEffect(() => {
		BCEvents.subscribe('SCNaeLogoGenerator-apply', parent, setRender.bind(null, render + 1));
		
		return () => {
			BCEvents.unsubscribe('SCNaeLogoGenerator-apply', parent);
		};
	}, [ render ]);

	const placeholder = placeholderLogo ? placeholderLogo : (<div {...className('nav-item-btn-base placeholder')}><span>nae,</span></div>);

	return (
		<Link to={href} {...className(`navbar-link`)}>
			{hasConfig() && !placeholderLogo ? 
				<div {...className('logo')}>{getUserLogo()}</div> :
				placeholder
			}
		</Link>
	);
});