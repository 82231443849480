import React from 'react';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../../colors.module.scss';
const { className } = new CssClassNames(styles);

export default function NaeImage({position = "top_left", relation = "1_1", inverseRelationMobile = false, customClass = null, customBgClass = null, url, title}) {

    const [, x, y] = (/([1-3])_([1-3])/gm).exec(relation);
    const [, posY, posX] = (/([^_]+)_([^_]+)/gm).exec(position);

    const wrapperPos = posX === 'left' ? 'ml-n5 ml-sm-0' : 'mr-n5 mr-sm-0'
 
    const positionVertical = posY !== 'top' ? `paddingTop-${y}` : `paddingBottom-${y}`;
    const positionHorizontal = posX !== 'left' ? `paddingLeft-${x}`: `paddingRight-${x}`;

    return (
        <div {...className(`${wrapperPos} d-flex h-100 ${customBgClass ? colors[customBgClass] : colors['bg-primary']}`)} >
            <div {...className(`w-100 wrapper-img ${positionVertical} ${positionHorizontal} ${customClass ? customClass : ''}`)}>
                <img {...className(`${styles.img}`)} src={url} alt={title} />
            </div>
        </div>
    )
}
