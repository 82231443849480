import React from 'react'

import { Container } from '../../../components/Bootstrap/Grid'

import CssClassNames from '../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className: cls } = new CssClassNames(styles);

export default function ContainerHome({children, className, ...props}) {
    return (
        <Container {...cls(`${styles['container-home']} ${className ? className : ''}`)} {...props}>
            {children}
        </Container>
    )
}
