import React from 'react';

import iconArrow from '../../assets/ic-arrow-download.svg';

import Button from '../Bootstrap/Button';

const DownloadButton = ({children, ...props}) => {
    return (
        <Button {...{ icon: iconArrow, ...props, download: true, outline: true }}>
            {children}
        </Button>
    )
}

export default DownloadButton;
