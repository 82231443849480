import ReactDOM from 'react-dom';

const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null;

const Portal = ({ children }) => {

    if (!portalRoot) {
        return null;
    }
    
    portalRoot.style.height = 0;
    portalRoot.style.width = '100%';
    portalRoot.style.position = 'absolute';
    portalRoot.style.top = '0';
    portalRoot.style.left = '0';

    return ReactDOM.createPortal(children, portalRoot);

};

export default Portal;