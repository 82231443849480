class BCEvents {

    events = {}

    createWindowAlias () {
        if(window) { 
            window.dispatchBCEventListener = this.trigger.bind(this);
            window.addBCEventListener = this.subscribe.bind(this);
            window.removeBCEventListener = this.unsubscribe.bind(this); 
            window.BCEvents = this.events;
            console.log('Show list of events from window.BCEvents')
        }
    }

    create(eventName) {
        if (this.events[eventName]) {
            return;
        }
        
        this.events[eventName] = {};
    }

    subscribe(eventName, listenerName, listenerFunction, once) {
        if(!Object.keys(this.events).includes(eventName)) {
            throw new Error(`-*- ERROR subscribe: (${eventName}) does not exist -*-`);
        } else if (!once && Object.keys(this.events[eventName]).includes(listenerName)) {
            throw new Error(`-*-  ERROR subscribe: This event has this listener name (${listenerName}) already in use -*-`);
        } else {            
            this.events[eventName][listenerName] = { listenerFunction, once };
        }
    }

    trigger (eventName, target) {
        if(Object.keys(this.events).includes(eventName)) {
            Object.keys(this.events[eventName]).forEach(listenerName => {
                const listenerFunction = this.events[eventName][listenerName].listenerFunction;
                const once = this.events[eventName][listenerName].once;
                listenerFunction({ eventName, listenerName, target});
                if(once) {
                    this.unsubscribe(eventName, listenerName);
                }
            });
        } else {
            throw new Error(`-*- ERROR trigger: (${eventName}) event does not exist -*-`);
        }
    }

    unsubscribe(eventName, listenerName) {
        if(Object.keys(this.events).includes(eventName)) {
            const newListeners = {...this.events[eventName]};
            delete newListeners[listenerName];

            this.events = {
                ...this.events,
                [eventName]: newListeners
            }
        } else {
            throw new Error(`-*- ERROR unsubscribe: (${eventName}) event does not exist -*-`);
        }
    }

}

const events = new BCEvents();


export default events;