import React from 'react';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const LayoutLogin = ({ children }) => ( 
    <div {...className(`layout-login layout-full-height`)}>
        {children}
    </div>
)

export default LayoutLogin;
