import React from 'react'

import Container from '../Container';
import { Row, Col } from '../../../../components/Bootstrap/Grid';
import NaeImage from '../../parts/NaeImage';
import Img from '../../assets/office-1.jpg'
import Img2 from '../../assets/image-woman.jpg'

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../../colors.module.scss';
const { className } = new CssClassNames(styles);

export default function FirstSection({firstText, secondTitle, secondText}) {
    return (
        <section {...className(`${styles['first-section']}`)}>
            <Container>
                <Row>
                    <Col col={{xs: 12, lg: 7}} mb={{xs: 7, lg: 0}} extraProps={{['data-sal']: "slide-up"}}>
                        <NaeImage
                            url={Img}
                            title="Office startup"
                            relation="2_1"
                            position="bottom_right"
                            customBgClass="bg-secondary"
                            customClass={styles['first-img']}
                        />
                    </Col>
                    <Col col={{xs: 12, lg: 5}}>
                        <div {...className(`${styles['first-text']} ${colors['bg-secondary']} ml-n5 ml-sm-0 h-100`)} data-sal="slide-up" data-sal-delay="300">
                            <h3 {...className(`h3 text-black mb-0`)}>
                                {firstText}
                            </h3>
                        </div>
                    </Col>
                </Row>
                <Row {...className(`${styles['second-row']}`)}>
                    <Col col={{xs: 12, lg: 7}}  mb={{xs: 7, lg: 0}}>
                        <div {...className(`d-flex flex-column h-100 w-100 px-lg-10 px-xl-12 px-xxl-13`)} data-sal="slide-up" data-sal-delay="300">
                            <h2 {...className(`d1 font-weight-normal text-black mb-3 mb-lg-7  mb-xxl-10`)}>
                                {secondTitle}
                            </h2>
                            <p {...className(`h5 text-black mb-0`)}>
                                {secondText}
                            </p>
                        </div>
                    </Col>
                    <Col col={{xs: 12, lg: 5}} extraProps={{['data-sal']: "slide-up"}}>
                        <NaeImage
                            url={Img2}
                            title="Image woman"
                            relation="1_2"
                            position="bottom_left"
                            customClass={styles['second-img']}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
