import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import NavbarBootstrap from '../../../../components/Bootstrap/Navbar';
import NavbarBrandComponent from './NavbarBrandComponent';
import Button from '../../../../components/Bootstrap/Button';

import BCEvents from '../../../../modules/BCEvents/BCEvents';

import { navigateToPrevious } from '../../userconfig';
import { scrollIt } from '../../../../utils';

import strings from '../../strings.json';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../../colors.module.scss';
const { className } = new CssClassNames(styles);

const MobileMenuDropdown = ({ sections, layout, title, path, current, closeMenuFnc = () => {} }) => {
    const [ open, setOpen ] = useState(current ? true : false);

    return (
        <li {...className(`${sections && sections.length > 1 ? 'with-sections' : ''} ${open ? 'open' : ''}`)}>
            <a 
                href={path} 
                {...className(`${layout === 'full-width' ? 'nav-item-btn-base nav-item-btn-mobile' : 'main-link'}`)} 
                onClick={e => {
                    if (sections && sections.length > 1) {
                        e.preventDefault();
                        setOpen(!open);
                    }
                }}>
                {title}
            </a>
            {sections && sections.length > 1 ? (
                <ul {...className('list-unstyled menu-sections')}>
                    {sections.map((section, i) => (
                        <li key={i}>
                            <Link 
                                onClick={e => {
                                    if (current) {
                                        e.preventDefault();
                                        closeMenuFnc();

                                        const elem = document.getElementById(section.id);
                                        if (elem) {
                                            scrollIt(elem, 200, -120);
                                        }
                                    }
                                }} 
                                to={`${path}#${section.id}`}
                            >{section.title}</Link>
                        </li>
                    ))}
                </ul>
            ) : null}
        </li>
    )
}

const MobileMenuTrigger = ({ active = false, onClick }) => (
    <a 
        href="#" 
        onClick={e => {
            e.preventDefault();
            onClick();
        }} 
        {...className('ml-auto mr-0 mobile-menu-trigger')}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="56" viewBox="0 0 70 56">
            <path fill="#0082FF" d={active ? "M45.627 16l1.415 1.414-10.607 10.607 10.607 10.606-1.415 1.415-10.606-10.607-10.607 10.607L23 38.627l10.607-10.606L23 17.414 24.414 16l10.607 10.607L45.627 16z" : "M51 37v2H19v-2h32zm0-10v2H19v-2h32zm0-10v2H19v-2h32z"} />
        </svg>
    </a>
)

const NavbarItem = ({ active, href, title, ...props }) => (
    <Link 
        to={href}
        className={props.className ? props.className : ''}
        data-title={title}
    >
        <span>{title}</span>
    </Link>
)

export default function Navbar ({ navigatePages, currentPage, indexPath, lang }) {
    const [ showMobileMenu, setShowMobileMenu ] = useState(false);
    const [ applyDisabled, setApplyDisabled ] = useState(true);

    useEffect(() => {
        BCEvents.subscribe('SCNaeLogoGenerator-allowClick', 'Navbar', setApplyDisabled.bind(null, false));
        BCEvents.subscribe('SCNaeLogoGenerator-disallowClick', 'Navbar', setApplyDisabled.bind(null, true));

        return () => {
            BCEvents.unsubscribe('SCNaeLogoGenerator-allowClick', 'Navbar');
            BCEvents.unsubscribe('SCNaeLogoGenerator-disallowClick', 'Navbar');
        }
    }, []);

    const isFullWidth = currentPage.layout === 'full-width';

    const items = isFullWidth ? [] : navigatePages.map(p => ({ 
        ...p, 
        href: p.path, 
        active: currentPage && currentPage.id === p.id,
        className: p.layout === 'full-width' ? `${styles['nav-item-btn-base']} ${styles['nav-item-btn']}` : ''
    }));
    
    return (
        <>
            <NavbarBootstrap 
                navItems={items} 
                navAlign="right"
                {...className(`navbar ${colors['bb-secondary']} ${isFullWidth ? styles['nav-full-width'] : ''}`)}
                navClassName={styles.nav}
                fixed={true}
                NavItem={NavbarItem}
                navbarBrandContent={isFullWidth ? (
                    <div {...className('navbar-brand-full')}>
                        <Button 
                            outline={true} 
                            color="primary"
                            {...className('back-button')}
                            size="sm"
                            target="_self"
                            onClick={navigateToPrevious}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
                                <path fill="#0082FF" fillRule="evenodd" d="M6.403 0l1.053.971L2.825 5.31l-.78.706 5.455 5.01L6.452 12 0 6.002z"/>
                            </svg>
                            <span {...className('ml-1 d-none d-md-inline-block')}>{strings['generator.navbar.back'][lang]}</span>
                        </Button>
                        <h5 {...className('navbar-brand-title d-none d-md-block ml-4')}>{strings['generator.navbar.title'][lang]}</h5>
                    </div>
                ) : (
                    <NavbarBrandComponent href={indexPath} />
                )}
                extraContent={isFullWidth ? (
                    <Button 
                        color="primary" 
                        disabled={applyDisabled}
                        Tag="button"
                        onClick={() => {
                            BCEvents.trigger('SCNaeLogoGenerator-apply');
                        }}
                    >{strings['generator.navbar.apply'][lang]}</Button>
                ) : (
                    <MobileMenuTrigger active={showMobileMenu} onClick={setShowMobileMenu.bind(null, !showMobileMenu)} />
                )}
            />
            {isFullWidth ? null : (
                <div {...className(`mobile-menu ${colors['bg-primary']} ${showMobileMenu ? 'show' : ''}`)}>
                    <ul {...className('list-unstyled menu-pages')}>
                        {navigatePages.map((page, i) => (
                            <MobileMenuDropdown 
                                key={i} 
                                current={page.id === currentPage.id} 
                                closeMenuFnc={setShowMobileMenu.bind(null)}
                                {...page}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}