import React from 'react';
import BCEvents from '../../modules/BCEvents/BCEvents';
import { navigate } from 'gatsby';

BCEvents.create('SCNaeLogoGenerator-apply');
BCEvents.create('SCNaeLogoGenerator-setup');
BCEvents.create('SCNaeLogoGenerator-allowClick');
BCEvents.create('SCNaeLogoGenerator-disallowClick');

let logo = null;
let previous = null;

const populateLogo = () => {
    if (typeof window !== 'undefined' && window.localStorage.getItem('nae-logo-selected')) {
        logo = JSON.parse(window.localStorage.getItem('nae-logo-selected'));
    }
}

const setupLogo = nextLogo => {
    window.localStorage.setItem('nae-logo-selected', JSON.stringify(nextLogo.target));
    logo = nextLogo.target;

    navigateToPrevious();

    setTimeout(() => {
        setupColors();
    }, 0);
}

export const navigateToPrevious = () => {
    // TODO: recursos?
    navigate(window.location.href.includes('/pt-br/') ? '/pt-br/recursos/' : '/recursos/');
    //window.location.href = previous ? previous : '/recursos/';
}

export const setupPrevious = () => {
    if (previous) {
        return;
    }
    
    previous = document.referrer;
}

export const removeColors = (favicon = true) => {
    document.documentElement.style.removeProperty('--primary');
    document.documentElement.style.removeProperty('--secondary');
    document.documentElement.style.removeProperty('--tertiary');
    document.documentElement.style.removeProperty('--fourth');
    document.documentElement.style.removeProperty('--comma-bg');
    if (favicon) {
        setupFavicon();
    }
}

export const setupColors = (colors = null, favicon = true) => {
    if (!colors && logo) {
        colors = logo.color;
    }
    
    if (!colors) {
        return;
    }
    
    document.documentElement.style.setProperty('--primary', colors.c1);
    document.documentElement.style.setProperty('--secondary', colors.c3);
    document.documentElement.style.setProperty('--tertiary', colors.c2);
    document.documentElement.style.setProperty('--fourth', colors.c4);
    
    if (logo) {
        document.documentElement.style.setProperty('--comma-bg', `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'%3E%3Cpath fill='%23${logo.color.c1.replace('#', '').trim()}' fill-rule='evenodd' d='${logo.comma.p}'/%3E%3C/svg%3E%0A")`);
        if (favicon) {
            setupFavicon(logo.logo.id, colors.id);
            setTimeout(() => {
                setupFavicon(logo.logo.id, colors.id);
            }, 0);
        }
    } else if (favicon) {
        setupFavicon(null, colors.id);
        setTimeout(() => {
            setupFavicon(null, colors.id);
        }, 0);
    }
}

populateLogo();
BCEvents.subscribe('SCNaeLogoGenerator-setup', 'userconfig', setupLogo);

const getUserSvg = item => {
    if (!logo || !logo[item]) {
        return null;
    }

    return getSvg(logo[item].w, logo[item].h, logo.color.c1, logo[item].p);
}

export const getSvg = (w, h, color, p) => (
    <svg width={w} height={h}>
        <path fill={color} fillRule="evenodd" d={p} />
    </svg>
);

export const setupFavicon = (logoId = null, colorId = null) => {
    if (!logoId) {
        logoId = 15;
    }
    if (!colorId) {
        colorId = 'B';
    }

    const icons = document.querySelectorAll("link[rel*='icon']");
    icons.forEach(icon => {
        icon.href = `/nae_favicons/${logoId}${colorId}.png`;
    });
}

export const getUserLogo = () => (getUserSvg('logo'));
export const getUserComma = () => (getUserSvg('comma'));
export const hasConfig = () => (!!logo);
