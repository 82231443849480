import React, { memo, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import striptags from 'striptags';
import Portal from '../../Portal';

import CssClassNames from '../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const getPosition = (element, side) => {
    const posEl = element.getBoundingClientRect();
    const scrollTop = document.documentElement.scrollTop;
    const newPos = {}

    switch (side) {
        case 'right':
        case 'left':
            newPos['transform'] = side === 'left' ? 'translate(-100%, -50%)' : 'translateY(-50%)';
            newPos['top'] = scrollTop + posEl.top + (posEl.height / 2);
            newPos['left'] = side === 'left' ? posEl.left - 6 : posEl.right + 6;
            break;

        case 'top':
        case 'bottom':
            newPos['transform'] = side === 'top' ? 'translate(-50%, -100%)' : 'translateX(-50%)';
            newPos['top'] = side === 'top' ? scrollTop + posEl.top + 6 : scrollTop + posEl.bottom + 6;
            newPos['left'] = posEl.left + (posEl.width / 2);
            break;

        default:
            break;
    }

    return newPos;
}


const getSide = (posEl, side, possibles = ['right', 'bottom', 'left', 'top']) => {
    const space = side === 'top' || side === 'bottom' ? 150 : 300;

    switch (side) {
        case 'right':
            if (window.innerWidth - posEl[side] > space) {
                return side;
            }
            break;
        case 'left':
        case 'top':
            if (posEl[side] > space) {
                return side;
            }
            break

        case 'bottom':
            if (window.innerHeight - posEl[side] > space) {
                return side;
            }
            break;

        default:
            side = 'right';
            if (window.innerWidth - posEl[side] > space) {
                return side;
            }
            break;
    }
    possibles.splice(possibles.findIndex(val => val === side), 1);
    const newSide = possibles[0];
    return getSide(posEl, newSide, possibles);
}

const Tooltip = memo(({ children = "Tooltip", side = 'right', extraClass = '', posWrappedRef = null, triggerRef = null, baseRef = null, onHide = null }) => {

    const [render, setRender] = useState(false);
    const [show, setShow] = useState(false);
    const [finalSide, setFinalSide] = useState(side);
    const [position, setPosition] = useState(null);

    useEffect(() => {
        window.addEventListener('resize', hideTooltip);
        return (() => {
            window.removeEventListener('resize', hideTooltip);
        });
    }, []);

    const hideTooltip = () => {
        if (show) {
            setShow(false);
        }

        setTimeout(() => {
            setRender(false);
            if (onHide) {
                onHide();
            }
        }, 0);
    };

    const showTooltip = element => {
        setFinalSide(getSide(element.getBoundingClientRect(), side));
        setPosition(getPosition(element, getSide(element.getBoundingClientRect(), side)));
        setRender(true);
        return setTimeout(hideTooltip, 1200);
    }

    const handleClickTooltip = (e) => {
        if (render) {
            return null;
        }
        
        const posEl = posWrappedRef ? posWrappedRef : e.target;
        return showTooltip(posEl);
    };

    useEffect(() => {
        let interval = null;

        if (baseRef && baseRef.current) {
            interval = showTooltip(baseRef.current);
        }
        
        if (triggerRef && triggerRef.current) {
            triggerRef.current.addEventListener('click', handleClickTooltip);
        }

        return (() => {
            if (interval) {
                clearInterval(interval);
            }

            if (triggerRef && triggerRef.current) {
                triggerRef.current.removeEventListener('click', handleClickTooltip);
            }
        });
    }, []);

    useEffect(() => {
        if (render) {
            setTimeout(() => {
                setShow(true);
            }, 0);
        }
    }, [ render ]);

    const contentFiltered = striptags(children, ['a', 'b', 'i', 'u', 's', 'strong', 'em', 'br']);

    return render ? (
        <Portal>
            <div {...className(`tooltip bs-tooltip-${finalSide} fade
                    ${show ? 'show' : ''}
                    ${extraClass ? extraClass : ''}
                `)}
                style={position}
                role="tooltip"
            >
                <div {...className('tooltip-inner text-tooltip')}>
                    {parse(contentFiltered)}
                </div>
                <div {...className(`arrow
                    ${finalSide === 'right' | finalSide === 'left' ? 'arrow-vertical-center' : 'arrow-center'}
                `)}></div>
            </div>
        </Portal>
    ) : null;

});

export default Tooltip;