import React from 'react'

import Container from '../Container';
import { Row, Col } from '../../../../components/Bootstrap/Grid';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

export default function TextSection({text}) {
    return (
        <section {...className(`${styles['text-section']}`)}>
            <Container>
                <Row>
                    <Col col={{xs: 12, lg: 4}} offset={{lg: 2}}>
                        <p data-sal="slide-up" {...className('h5 text-black mb-0')}>
                            {text}
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
