import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import HeroSection from './HeroSection';
import TextSection from './TextSection';
import FirstSection from './FirstSection';
import VideoSection from './VideoSection';
import TagSection from './TagSection';
import HablamosSection from './HablamosSection';
import SomosSection from './SomosSection';
import Footer from './Footer';

import { setupFavicon } from '../userconfig';

import content from './content.json';

const getRandomKey = (min, max) => (Math.floor(Math.random() * (max - min)) + min);

const Home = ({ navigatePages = [], lang = 'es' }) => {
    const [ randomLogo, setRandomLogo ] = useState(null);

    const data = useStaticQuery(graphql`
        {
            brandCenterSection(rows: {elemMatch: {cols: {elemMatch: {items: {elemMatch: {type: {eq: "nae_logo_generator"}}}}}}}) {
                id
                rows {
                    cols {
                        items {
                            naeColors {
                                c1
                                c2
                                c3
                                c4
                                id
                            }
                            naeLogos {
                                id
                                p
                            }
                            naeCommas {
                                id
                                p
                            }
                            naeConcepts {
                                concept
                                concept_pt
                                id
                                colors {
                                    r
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const dataConcepts = data.brandCenterSection.rows[0].cols[0].items[0];

    useEffect(() => {
        if (!randomLogo) {
            const randomKey = getRandomKey(0, dataConcepts.naeLogos.length - 1);
            setRandomLogo(dataConcepts.naeLogos[randomKey].id);
            setupFavicon(dataConcepts.naeLogos[randomKey].id);
        }
    }, [ randomLogo ]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.sessionStorage.setItem('lang', lang);
        }
    }, [ lang ]);
    
    return (
        <>
            <HeroSection 
                title={content.heroSection.title[lang]}
                loginText={content.heroSection.loginText[lang]}
                languageText={content.heroSection.languageText[lang]}
                path={navigatePages && navigatePages.length ? navigatePages[0].path : `/login/?lang=${lang}`}
                logo={randomLogo ? dataConcepts.naeLogos.find(i => i.id === randomLogo) : null}
                comma={randomLogo ? dataConcepts.naeCommas.find(i => i.id === randomLogo) : null}
                lang={lang}
            />
            <TextSection 
                text={content.textSection.text[lang]}
            />
            <FirstSection 
                firstText={content.firstSection.firstText[lang]}
                secondTitle={content.firstSection.secondTitle[lang]}
                secondText={content.firstSection.secondText[lang]}
            />
            <VideoSection 
                title={content.videoSection.title[lang]}
            />
            <TagSection 
                title={content.tagSection.title[lang]}
                text={content.tagSection.text[lang]}
                tagTitle={content.tagSection.tagTitle[lang]}
                concepts={dataConcepts}
                lang={lang}
                logoId={randomLogo}
            />
            <HablamosSection
                firstTitle={content.hablamosSection.firstTitle[lang]}
                firstText={content.hablamosSection.firstText[lang]}
                textItems={content.hablamosSection.textItems[lang]}
            />
            <SomosSection 
                firstTitle={content.somosSection.firstTitle[lang]}
                firstText={content.somosSection.firstText[lang]}
                secondText={content.somosSection.secondText[lang]}
            />
            <Footer 
                logo={randomLogo ? dataConcepts.naeLogos.find(i => i.id === randomLogo) : null}
                linksItems={content.footer.linksItems[lang]}
            />
        </>
    )
    
};

export default Home;