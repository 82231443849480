import React from 'react'

import CssClassNames from '../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);


export default function SocialIcons({icons, size = 24, padding = 2, ...props}) {
    return (
        <ul {...className(`${styles['social-list']} ${props.className ? props.className : ''}`)}>
            {icons.map(({ url = '#', path ='', kind}, i) => (
                <li key={i} {...className(`d-inline-block ${i === icons.length - 1 ? 'mr-0' : `mr-${padding}`}`)}>
                    <a href={url} target="_blank" rel="noopener noreferrer" alt={kind}>
                        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                            <path 
                                style={parseInt(size) !== 24 ? { transform: `scale(${parseInt(size) / 24})` } : null} 
                                d={path ? path : `M0 0h${size}v${size}H0z`}
                            />
                        </svg>
                    </a>
                </li>
            ))}
        </ul>
    )
}
