import React, { useState, useEffect } from 'react';

const commas = [
    // [ size, color, path fill ]
    [ 64, '#0034EB', 'M24.1,16L16,49h19.9L48,16H24.1z' ],
    [ 144, '#0082FF', 'M68.4,74.5c-0.8,0.2-1.6,0.2-2.6,0.2c-8.9,0-13.8-6.8-13.8-14.3C52,51.5,59,44,70.3,44c13.6,0,21.2,10.9,21.2,25.6c0,18.7-12.3,29.7-25.8,29.7c-9.2,0-13.4-5.2-13.4-10.7c0-1,0.2-2.1,0.5-2.8C62.7,85.9,67.5,81,68.4,74.5' ],
    [ 144, '#69DB65', 'M74.3,45c-11.8,0-17.7,6.2-17.7,15.2c0,11.7-1.2,25.4-4.6,38.8h19.4C83.4,84.3,92,68.1,92,59.8C92,51.2,86.5,45,74.3,45z' ],
    [ 144, '#FF3B56', 'M69.5,45v39.1H58V100h28V45H69.5z' ],
    [ 144, '#2ADAC0', 'M70.5,41C82.6,41,90,53,90,65.7C90,86.7,74.4,99,58.7,103l-0.2-0.3C76.7,90.8,72.9,67.4,59,67.4C50.1,58.2,56.9,41,70.5,41' ],
    [ 144, '#FE9E00' ,'M60.8,45L53,100h23.4L92,45H60.8z' ],
    [ 144, '#724DF9', 'M57.1,45v27.7H74L57,99h14.4L88,72.7V45H57.1z' ]
];

let timeout = null;

const getRandomTime = () => (Math.floor(Math.random() * 500) + 200);

const firstSpinnerKey = 1;

export default function Comma({ className = '', size = null, spinner = false, customComma = null }) {
    const [ itemKey, setItemKey ] = useState(spinner ? firstSpinnerKey : 0);

    useEffect(() => {
        if (spinner) {
            timeout = setTimeout(() => {
                setItemKey(itemKey === commas.length - 1 ? firstSpinnerKey : itemKey + 1);
            }, getRandomTime());
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, [ itemKey ]);

    const comma = !spinner && customComma ? customComma : commas[itemKey];

    const finalSize = size ? size : comma[0] ;
    const scale = finalSize / comma[0];

    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width={finalSize.x || finalSize} height={finalSize.y || finalSize} viewBox={`0 0 ${finalSize.x || finalSize} ${finalSize.y || finalSize}`}>
            <path {...typeof finalSize !== 'object' && {scale: scale}} fillRule="evenodd" clipRule="evenodd" fill={comma[1]} d={comma[2]}/>
        </svg>
    )
}