import React from 'react';
import Img from "gatsby-image";
import get from 'lodash.get';

//import { BREAKPOINTS } from '../../sharedComponents/variables';

import CssClassNames from '../../sharedComponents/scss/CssClassNames';
const { className } = new CssClassNames();

/*
const bpNames = Object.keys(BREAKPOINTS)
    .reduce((bps, key) => ([...bps, key]), [])
    .sort((a, b) => (BREAKPOINTS[a] - BREAKPOINTS[b]));
*/

const GSImage = ({ gatsbyImage, title, srcset, defaultSrc }) => {
 
    const { childImageSharp, publicURL } = gatsbyImage || {};
    // TODO: Different images by breakpoint is broken now
    if (srcset) {
        return (
            <>
                {Object.values(srcset).filter(i => i.gatsbyImage).map(({ gatsbyImage }, i) => {
                    if (gatsbyImage.childImageSharp) {
                        return (
                            <Img key={i} {...gatsbyImage.childImageSharp} alt={title} />
                        );
                    } else if (gatsbyImage.publicURL) {
                        return (
                            <img key={i} {...className(`w-100`)} src={gatsbyImage.publicURL} alt={title} />
                        );
                    }

                    return null;
                })}
            </>
        )
    }
    /*
    if (srcset) {
        return (
            <>
                {bpNames.map((bpName, i) => {
                    const antBpName = i > 0 && bpNames[i - 1];
                    const nextBpName = i < (bpNames.length - 1) && bpNames[i + 1];
                    const fluid = get(srcset, `[${bpName}]gatsbyImage.childImageSharp.fluid`);
                    
                    if (fluid) {
                        return (
                            <div key={bpName} {...className(`
                                ${antBpName ? `d-none d-${bpName}-block` : `.d-${nextBpName}-none`}  
                                ${nextBpName && `d-${nextBpName}-none`} 
                            `)} >
                                <Img fluid={fluid} alt={title} />
                            </div>
                        );
                    }
                    return null;
                })}
            </>
        );
    }
    */
    const fluid = get(childImageSharp, `fluid`);
    if (fluid) {
        return (
            <Img fluid={fluid} alt={title} />
        );
    }

    if (publicURL) {
        return (
            <img {...className(`w-100`)} src={publicURL} alt={title} />
        );
    }

    if (defaultSrc) {
        return (
            <img {...className(`w-100`)} src={defaultSrc} alt={title} />
        );
    }

    return null;

};

export default GSImage;