import React, {useState} from 'react'

import Container from '../Container';
import { Row, Col } from '../../../../components/Bootstrap/Grid';
import { setupColors, removeColors, setupFavicon } from '../../userconfig';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../../colors.module.scss';
const { className } = new CssClassNames(styles);

const initialColor = {id: '', color: '#0082FF'};

export default function TagSection({title, text, tagTitle, concepts, lang = 'es', logoId = null}) {
    const [lastColor, setLastColor] = useState(initialColor);

    const handleTagClick = (item) => (e) => {
        e.preventDefault();
        
        if (item.id === lastColor.id) {
            if (document.activeElement) {
                document.activeElement.blur();
            }
            
            removeColors(false);
            setupFavicon(logoId);
            setLastColor(initialColor);
            return;
        }

        const sortedColors = item.colors.sort((a, b) => a.r >= b.r ? -1 : 1);
        const filteredColors = sortedColors.filter((item) => item.r >= sortedColors[0].r);
        let resultColor = filteredColors[Math.floor(Math.random() * filteredColors.length)];
        let resultC1 = concepts.naeColors.find(item => item.id === resultColor.id);
        
        while (resultC1.c1 === lastColor.color) {
           resultColor = filteredColors.length > 1 ? filteredColors[Math.floor(Math.random() * filteredColors.length)] : sortedColors[1];
           resultC1 = concepts.naeColors.find(item => item.id === resultColor.id);
        }

        setLastColor({ 
            id: item.id, 
            color: resultC1.c1 
        });

        const colors = concepts.naeColors.find(item => item.id === resultColor.id);

        setupColors(colors, false);
        setupFavicon(logoId, colors.id);
    }

    return (
        <section {...className(`${styles['tag-section']} ${colors['bg-primary']}`)}>
            <Container>
                <Row>
                    <Col col={{xs: 12, lg: 10}} offset={{lg: 1}} mb={{xs: 5, xl: 7, xxl: 10}}>
                        <h2 {...className('d1 font-weight-normal text-center text-white mb-0')} data-sal="slide-up">
                            {title}
                        </h2>
                    </Col>
                    <Col col={{xs: 12, lg: 10, xl: 8}} offset={{lg: 1, xl: 2}}>
                        <p {...className('h5 font-weight-normal text-center text-white mb-0')} data-sal="slide-up">
                            {text}
                        </p>
                    </Col>
                    <Col col={{xs: 12, lg: 10}} offset={{lg: 1}} mb={{xs: 7, xxl: 8}} {...className('title-tags')}>
                        <h3 {...className('h2 font-weight-normal text-center text-white mb-0')} data-sal="slide-up">
                            {tagTitle}
                        </h3>
                    </Col>
                    <Col col={12}>
                        <ul {...className('tag-list d-flex flex-wrap justify-content-center mb-0')}>
                            {concepts.naeConcepts.map((item, i) => (
                                <li key={item.id} {...className('d-flex mr-1 mr-lg-2 mr-xxl-3')} data-sal="slide-up">
                                    <a href='#' onClick={handleTagClick(item)} role='button' {...className(`tag-link ${item.id === lastColor.id ? 'active': ''} h5 mb-1 mb-lg-2 mb-xxl-3`)}>
                                        {lang === 'es' ? item.concept : item.concept_pt} 
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
