import React from 'react';
import Scrollspy from 'react-scrollspy';

import { scrollIt } from '../../../../utils'; 

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../../colors.module.scss';
const { className } = new CssClassNames(styles);

const Sidebar = ({ items, show, scrollOffset = 0 }) => {
    return (
        <div {...className(`layout ${show ? 'show' : ''} d-none d-lg-block ${colors['bg-fourth']} ${colors['br-secondary']}`)}>
            <Scrollspy 
                offset={scrollOffset * 2}
                componentTag="div" 
                {...className(`list-group`)}
                items={items.map(i => i.id)}
                currentClassName={styles.active}
            >
                {items ? items.map(({ id, title }) => (
                    <a 
                        key={id} 
                        {...className('list-group-item list-group-item-action')} 
                        href={`#${id}`}
                        onClick={e => {
                            e.preventDefault();
                            const element = document.getElementById(id);
                            if (element) {
                                scrollIt(element, 3, scrollOffset);
                            }
                        }}
                    >
                        {title}
                    </a>
                )) : null}
            </Scrollspy>
        </div>
    );
}

export default Sidebar;