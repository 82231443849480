import React from 'react';

import Navbar from '../Navbar';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

export default ({ children, navigatePages, currentPage, lang, indexPath }) => (   
    <main {...className(`layout-navbar layout-full-height layout-full`)}>
        <Navbar indexPath={indexPath} lang={lang} navigatePages={navigatePages} currentPage={currentPage} />
        <div {...className('layout-full-inner')}>
            {children}
        </div>
    </main>
);