import React, { useState, useRef, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { parseTemplateContent as parse } from '../../../../utils';
import { Row, Col, Container } from '../../../../components/Bootstrap/Grid';
import SCHeading from '../../../../components/SCHeading';
import Button from '../../../../components/Bootstrap/Button';

import CssClassNames from '../../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../../colors.module.scss';
const { className } = new CssClassNames(styles);

let bodyListener = null;

const Footer = () => {
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        if (showDropdown) {
            if (bodyListener) {
                document.removeEventListener('click', bodyListener);
            }

            bodyListener = () => {
                setShowDropdown(false);
            }

            document.addEventListener('click', bodyListener);
        } else if (bodyListener) {
            document.removeEventListener('click', bodyListener);
            bodyListener = null;
        }
    }, [ showDropdown ]);

    return (
        <StaticQuery
            query={graphql`
                {
                    brandCenterSite {
                        s3PublicUrl
                    }
                    brandCenterVersion {
                        name
                    }
                    allBrandCenterVersionMenu {
                        edges {
                            node {
                                active
                                slug
                                published
                                name
                                id 
                            }
                        }
                    }
                    brandCenterTemplate {
                        values {
                            footerTitle
                            footerHtml {
                                value
                                kind
                            }
                            footerCopy
                        }
                    }
                }
            `}
            render={({
                brandCenterSite,
                allBrandCenterVersionMenu,
                brandCenterTemplate,
                brandCenterVersion
            }) => {
                return (
                    <footer {...className(`${styles.footer} mt-10 ${colors['bg-fourth']} ${colors['bt-secondary']}`)}>
                        <Row 
                            pt={{ xs: 5, lg: 5 }} 
                            pb={{ xs: 5, lg: 7 }} 
                            gutter={false}
                        >
                            <Col col={{ lg: 3 }} />
                            <Col col={{ lg: 9 }}>
                                <Container>
                                    <Row className="align-items-center" pb={{ xs: 4, lg: 3 }}>
                                        <Col {...className('primary-link-cont')} col={{ xs: 6 }}>
                                            <SCHeading tag="h5" content={brandCenterTemplate.values.footerTitle} />
                                        </Col>
                                        <Col {...className('text-right')} col={{ xs: 6 }}>
                                            <div {...className(`dropup ${showDropdown ? 'show' : ''}`)}>
                                                <Button 
                                                    {...className('dropdown-toggle btn-dropdown')} 
                                                    kind="button"
                                                    tag="button"
                                                    outline={true}
                                                    color="primary"
                                                    onClick={() => { setShowDropdown(!showDropdown); }}
                                                >
                                                    <span>{brandCenterVersion.name}</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8">
                                                        <path fill="#0082FF" fillRule="evenodd" d="M.5 2.231l.647-.701L4.04 4.617l.47.52L7.85 1.5l.65.699L4.501 6.5z"/>
                                                    </svg>
                                                </Button>
                                                <div {...className(`dropdown-menu dropdown-menu-right ${showDropdown ? 'show' : ''}`)} aria-labelledby="dropdownMenuButton">
                                                    {allBrandCenterVersionMenu.edges.filter(({ node }) => node.published).map(({ node }, i) => (
                                                        <a 
                                                            key={i}
                                                            {...className(`dropdown-item ${node.active ? 'active' : ''}`)} 
                                                            href={`${brandCenterSite.s3PublicUrl}/${node.active ? '' : node.slug}`}
                                                        >{node.name}</a>
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col {...className('link')} col={{ lg: 7 }} pb={{ xs: 2, lg: 0 }}>
                                            {parse(brandCenterTemplate.values.footerHtml)}
                                        </Col>
                                        <Col {...className(`${colors['c-primary']} copyright`)} col={{ lg: 5 }}>
                                            {parse(brandCenterTemplate.values.footerCopy)}
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </footer>
                );
            }}
        />
    );
};

export default Footer;