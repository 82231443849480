import parse from 'html-react-parser';

export const generatePaddingClasses = (paddings = {}, side , styles = [], propName='p') => {
    const newStyles = [...styles];
    Object.keys(paddings).forEach( bp => {
        if (paddings[bp]) {
            if (bp === 'xs') {
                newStyles.push(`${propName}${side}-${paddings[bp]}`);
            } else {
                newStyles.push(`${propName}${side}-${bp}-${paddings[bp]}`);
            }
        }
    });
    return newStyles;
}

export const parseTemplateContent = (content) => {
    if(content && content.kind && content.kind === 'html') {
        return parse(content.value || '');
    }

    if (typeof content === 'string') {
      return parse(content);
    }

    return content;
}

export const findVariableByName = (name, variables = []) => {
    return variables.length > 0 ? variables.find((item) => item.name === name) : {};
}

export const copyToClipboardValue = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

const getDomain = () => {
    if (typeof window === 'undefined') {
        return '';
    }

    const parts = window.location.href.split("/");
    return `${parts[0]}//${parts[2]}`;
}

export const copyToClipboardDiv = (selectorToCopy, parentSelector = null, htmlFormat = false) => {
    const parent = parentSelector ? document.querySelector(parentSelector) : document;
    const preview = parent.querySelector(selectorToCopy);

    if (htmlFormat) {
        return copyToClipboardValue(preview.innerHTML.replace(/src="\//g, `src="${getDomain()}/`));
    }

    preview.contentEditable = true;
    preview.focus({preventScroll: true});
    document.execCommand('selectAll');
    document.execCommand("copy");
    setTimeout(() => {     
      window.getSelection().removeAllRanges();
      preview.contentEditable = false;
      preview.blur();
    }, 0);
}

export const signatureParser = new RegExp(/\(([a-zA-Z]+)\)\{([^|}]*)\}/ig);
export const signatureImageParser = new RegExp(/(<img[^>s]*src=["'])([^"']*)(["'][^>]*>)/ig);
export const styleLinkParser = new RegExp(/<a *(style="([^"]+)")? *([^>]*)>/ig);
export const variablesUrlParser = new RegExp(/\${([^|}]*)\}/ig);

export const matchAllImgSrcFromSignature = (content) => {
    let matches;
    const arraySrc = [];

    while((matches = signatureImageParser.exec(content)) !== null) {
        arraySrc.push(matches[2]);
    }
    return arraySrc;
}

export const matchAllVariablesFromSignature = (content) => {
    let matches;
    const arraySrc = [];

    while((matches = signatureParser.exec(content)) !== null) {
        arraySrc.push({
            type: matches[1],
            name: matches[2]
        });
    }
    return arraySrc;
}

export const matchAllVariablesUrl = (content) => {
    let matches;
    const arraySrc = [];

    while((matches = variablesUrlParser.exec(content)) !== null) {
        arraySrc.push(matches[0]);
    }
    return arraySrc;
}

export const validEmail = (mail) => {
    const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const email = mail.toLowerCase();
    return emailValidator.test(email);
}

export const getElementOffsetTop = (destination, offset = 0) => {
    let destinationOffset = 0;
    
    if (typeof destination === 'number') {
        destinationOffset = destination + offset;
    } else {

        const bodyOffset = document.body.getBoundingClientRect();
        const viewportOffset = destination.getBoundingClientRect();
        if (viewportOffset && viewportOffset.top) {
            destinationOffset = viewportOffset.top - bodyOffset.top + offset;
        } else {
            destinationOffset = destination.offsetTop + offset;
        }
    }

    if (destinationOffset < 0) {
        destinationOffset = 0;
    }

    return destinationOffset;
}

export const scrollIt = (destination, speed = 200, offset = 0, easing = 'linear', callback) => {

    const easings = {
      linear(t) {
        return t;
      },
      /*
      easeInQuad(t) {
        return t * t;
      },
      easeOutQuad(t) {
        return t * (2 - t);
      },
      easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      },
      easeInCubic(t) {
        return t * t * t;
      },
      easeOutCubic(t) {
        return (--t) * t * t + 1;
      },
      easeInOutCubic(t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      },
      easeInQuart(t) {
        return t * t * t * t;
      },
      easeOutQuart(t) {
        return 1 - (--t) * t * t * t;
      },
      easeInOutQuart(t) {
        return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
      },
      easeInQuint(t) {
        return t * t * t * t * t;
      },
      easeOutQuint(t) {
        return 1 + (--t) * t * t * t * t;
      },
      easeInOutQuint(t) {
        return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
      }
      */
    };
  
    const start = window.pageYOffset;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
  
    const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;

    const destinationOffset = getElementOffsetTop(destination, offset);
    
    const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);
    const duration = Math.abs(destinationOffsetToScroll - start) / speed;

    if ('requestAnimationFrame' in window === false) {
      window.scroll(0, destinationOffsetToScroll);
      if (callback) {
        callback();
      }
      return;
    }
  
    function scroll() {
      const now = 'now' in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, ((now - startTime) / duration));
      const timeFunction = easings[easing](time);
      window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));
  
      if (window.pageYOffset === destinationOffsetToScroll) {
        if (callback) {
          callback();
        }
        return;
      }
  
      requestAnimationFrame(scroll);
    }
  
    scroll();
  }
  
export const getContrastColor = (hex, ratio = 161, color1 = 'black', color2 = 'white') => {
    var r = parseInt(hex.substr(1, 2), 16),
        g = parseInt(hex.substr(3, 2), 16),
        b = parseInt(hex.substr(5, 2), 16),
        yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= ratio) ? color1 : color2;
}
